import clsx from "clsx";
import Image from "next/image";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import useMeasure from "react-use-measure";

import EntryThumbnailHeader from "@components/EntryThumbnailHeader";
import { ArrowRight } from "@components/Icons";
import Target from "@components/Target";
import fallbackImage from "@images/abstract/floor_dark_green_1.FINAL.jpg";
import getImageBlurOptions from "@lib/getImageBlurOptions";
import DotBorder from "@components/DotBorder";
import { getEntryCategoryArray } from "@lib/getEntryCategoryArray";
import Spacer from "./Spacer";

const EntryThumbnail = ({
	image,
	imageBlur,
	heading,
	summary,
	target,
	categories = [],
	entry = [],
	size = "md",
	authors = [],
	externalUrl = "",
	indentMobile = false,
	date = null,
	onImageHeight = (height) => null,
	aspectRatio = "landscape",
	source = null,
	measureImageHeight = true,
}) => {
	const { ref, inView } = useInView();

	const [imageRef, imageBounds] = useMeasure();

	useEffect(() => {
		if (measureImageHeight) onImageHeight(imageBounds.height);
	}, [measureImageHeight, onImageHeight, imageBounds.height]);

	const targetTarget = externalUrl ? { type: "url", url: externalUrl } : target;

	let resourceType = null;
	{
		categories.map((category) => {
			if (category.slug == "webinars" || category.slug == "videos") {
				resourceType = "video";
			}
			if (
				category.slug == "threat-intelligence-reports" ||
				category.slug == "threat-reports" ||
				category.slug == "white-papers"
			) {
				resourceType = "downloadable";
			}
		});
	}

	return (
		<div
			className={clsx(
				"EntryThumbnail relative box-sizing bg-white-01",
				"transition-opacity duration-500 ease-out",
				"h-full",
				{
					"opacity-0": !inView,
					"opacity-100": inView,
				}
			)}
			ref={ref}
		>
			<div className={clsx("EntryThumbnail__imageContainer")} ref={imageRef}>
				<Target
					target={targetTarget}
					className={clsx("EntryThumbnail__imageWrapper", "block", "mb-7.5")}
				>
					{!!image && !!image.url && (
						<Image
							src={image.url}
							height={image.height}
							width={image.width}
							alt={image.title}
							layout="responsive"
							sizes={
								imageBounds.width !== undefined
									? `${Math.round(imageBounds.width)}px`
									: "100vw"
							}
							className={clsx("EntryThumbnail__image")}
							draggable={false}
							{...getImageBlurOptions(image, imageBlur)}
						/>
					)}
					{!image && <div className="border-t-[3px] border-yellow-01"></div>}
				</Target>
			</div>

			<div
				className={clsx("EntryThumbnail__caption", "relative", {
					"md:pb-22 md:px-5": !!image,
					"md:pb-15": !image,
				})}
			>
				<EntryThumbnailHeader
					heading={heading}
					categories={getEntryCategoryArray(entry)}
					authors={authors}
					size={size}
					target={targetTarget}
					indentMobile={indentMobile}
					date={date}
					source={source}
				/>
			</div>
			<Target
				target={targetTarget}
				className={clsx(
					"EntryThumbnail__target",
					"flex items-center",
					{ "md:py-7.5 px-5": !!image },
					"hover-underline font-medium",
					"group",
					"md:absolute",
					"md:bottom-0 md:left-0 md:right-0 w-full"
				)}
			>
				<div
					className={clsx(
						"EntryThumbnail__targetIcon",
						"mr-4 text-violet-01",
						"transition-transform group-hover:translate-x-1"
					)}
				>
					<ArrowRight />
				</div>
				<span className={clsx("inline-block pb-[2px]")}>
					{resourceType && resourceType == "video" && "Watch Now"}
					{resourceType && resourceType == "downloadable" && "Download Now"}
					{!resourceType && "Read More"}
				</span>
			</Target>
			{!!image && <DotBorder />}
		</div>
	);
};

export default EntryThumbnail;
