import { useUpdateAtom } from "jotai/utils";
import { useEffect } from "react";

import { headerThemeAtom } from "@atoms/siteHeader";

const useSetHeaderTheme = (headerTheme) => {
  const setHeaderTheme = useUpdateAtom(headerThemeAtom);

  return useEffect(() => {
    setHeaderTheme(headerTheme);
  }, [setHeaderTheme, headerTheme]);
};

export default useSetHeaderTheme;
