import clsx from "clsx";
import Link from "next/link";

import Author from "@components/Author";
import getHref from "@lib/routes";

const AuthorsPlus = ({ authors }) => {
  const author = authors[0]
  const href = getHref(author);
  if (author) {
    return (
      <div className={clsx("Authors", "flex flex-wrap", "space-x-7.5")}>
            <Link key={author.id} href={href}>
              <a
                className={clsx(
                  "Authors__author",
                  "mt-7.5",
                  "block",
                  "transition-colors",
                  "hover:text-violet-01",
                  "w-full flex"
                )}
              >
                <Author
                  name={author.title}
                  image={author.image ? author.image[0] : null}
                />
                {authors.length > 1 && (
                  <div
                    className="Author flex flex-shrink pt-0.5 ml-3 px-3 rounded-full bg-white-03">
                    {authors.length > 1 && '+' +  (authors.length - 1)}
                  </div>
                )}
              </a>
            </Link>
      </div>
    );
  }
  else {
    return null;
  }
};

export default AuthorsPlus;
